const scrollToTop = () => {
  if (typeof window !== 'undefined') {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
      })
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0)
    }
  }
}

export default scrollToTop
