const IS_DEV_ENV = process.env.NODE_ENV === 'development'

export const REFERRAL_REGEX = /^[A-Za-z0-9_\-]{1,64}$/
export const CREW_REGEX = /^[A-Za-z0-9_\-]{4,64}$/
export const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
export const DATE_REGEX = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.]([0-9][0-9])/
export const ZIP_CODE_REGEX = /^\d{5}$|^\d{5}-\d{4}$/
export const ROOT_DOMAIN = process.env.ROOT_DOMAIN

/****************** LOCAL ONLY *******************/
export const FUNCTIONS_URL_DEV = 'http://localhost:5001'
export const FIREBASE_CONFIG = process.env.FIREBASE_CONFIG
