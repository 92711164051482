import ReactGA from 'react-ga'
import React, { Fragment } from 'react'

// Deprecated for actions.js Line 77
export const initGA = (API_KEY) => {
  try {
    ReactGA.initialize(API_KEY)
  } catch (e) {
    // Firefox incognito throws an error, Chrome does not
    console.log('error initializing GA', e)
  }
}

export const logPageView = (pageName = null) => {
  if (process.env.NODE_ENV !== 'development' && pageName) {
    ReactGA.set({ page: pageName || window.location.pathname })
    ReactGA.pageview(pageName || window.location.pathname)
  }
}

export const logEvent = ({ category, action, label, value }) => {
  if (process.env.NODE_ENV !== 'development' && category && action) {
    ReactGA.event({ category, action, label, value })
  }
}

export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal })
  }
}

export const logSegEvent = (eventName, props = {}) => {
  // console.log('EVENT', eventName, props)
  if (typeof window !== 'undefined' && window.analytics) {
    window.analytics.track(eventName, { ...props, env: process.env.ENV, version: process.env.CURRENT_VERSION })
  }
}

export const logSegPage = (pageName, props = {}) => {
  // console.log('PAGE', pageName, props)
  if (typeof window !== 'undefined' && window.analytics) {
    window.analytics.page(pageName, { ...props, env: process.env.ENV, version: process.env.CURRENT_VERSION })
  }
}

export const logSegIdentity = (userId, traits = {}) => {
  // console.log('IDENTIFY', userId, traits)
  if (typeof window !== 'undefined' && window.analytics) {
    if (userId) {
      window.analytics.identify(userId, { ...traits, env: process.env.ENV, version: process.env.CURRENT_VERSION })
    } else {
      window.analytics.identify({ ...traits, env: process.env.ENV, version: process.env.CURRENT_VERSION })
    }
  }
}

export const Track = (props = {}) => {
  return (
    <Fragment>
      {React.Children.map(this.props.children, (child) => {
        return React.cloneElement(
          child,
          {
            onClick: () =>
              logSegEvent(props.event || '', {
                experiment: props.exp || props.experiment || '',
                variant: props.vrnt || props.variant || '',
              }),
          },
          null
        )
      })}
    </Fragment>
  )
}
