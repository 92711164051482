import React from 'react'
import PropTypes from 'prop-types'
import { initGA, logPageView } from '../lib/analytics'

const isDev = process.env.NODE_ENV === 'development'

export default class GA extends React.Component {
  componentDidMount() {
    // disable in dev mode
    if (isDev) return
    if (!process.env.NEXT_PUBLIC_GA_API_ID) {
      console.log('Google Analytics Key missing')
      return
    }
    if (!window.GA_INITIALIZED) {
      initGA(process.env.NEXT_PUBLIC_GA_API_ID)
      window.GA_INITIALIZED = true
    }
    // INFO: went in favor of manual page view logging as it gives me greater control about 'sub-pages'
    // logPageView();
  }

  render() {
    return <div>{this.props.children}</div>
  }
}

GA.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
